// services/registration.js
import { formendPoint } from '../apis';
import { apiConnector } from '../../services/apiConnector';  // Assuming axiosInstance is defined in this path


const {
    REGISTRATION_URL,
    RAZORPAY_VERIFY,
    RAZORPAY_ORDER
} = formendPoint


export const registrationService = {
    submitRegistration: async (formData) => {
        try {
            const response = await apiConnector('post', REGISTRATION_URL, formData, {
                'Content-Type': 'multipart/form-data',
            }, null);  // No params needed for registration
            return response.data;
        } catch (error) {
            throw error.response?.data || { message: 'Registration failed' };
        }
    },

    createPaymentOrder: async (data, id) => {  // Keep `id` as a separate argument
        try {
            console.log(id); // Log the ID to ensure it's being passed correctly
            const url = RAZORPAY_ORDER.replace(":id", id);  // Replace :id with actual registration ID
            const response = await apiConnector('post', url, data, null, null);
            return response.data;
        } catch (error) {
            throw error.response?.data || { message: 'Failed to create payment order' };
        }
    },
    

    verifyPayment: async (paymentData) => {
        try {
            const response = await apiConnector('post', RAZORPAY_VERIFY, paymentData, null, null);  
            return response.data;
        } catch (error) {
            throw error.response?.data || { message: 'Payment verification failed' };
        }
    }
};

