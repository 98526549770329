import React, { useState } from 'react';
import { directRegistrationService } from '../../services/operations/directService';
import toast from 'react-hot-toast';
// import  alert from '../../components/alert';


const DirectRegistrationForm = () => {
    const [step, setStep] = useState('form');
    const [loading, setLoading] = useState(false);
    const [registrationId, setRegistrationId] = useState(null);
    const [verificationCode, setVerificationCode] = useState('');
    const [apiError, setApiError] = useState('');

    const [formData, setFormData] = useState({
        name: '',
        fatherName: '',
        motherName: '',
        dob: '',
        gender: '',
        category: '',
        class: '',
        mobile: '',
        guardianMobile: '',
        aadhaar: '',
        email: '',
        city: '',
        district: '',
        state: '',
        pinCode: '',
        declaration: true
    });


    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};

        // Required field validation
        Object.keys(formData).forEach(key => {
            if (!formData[key] && key !== 'declaration') {
                newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
            }
        });

        // Email validation
        if (formData.email && !/^[A-Z0-9._%+-]+@gmail\.com$/i.test(formData.email)) {
            newErrors.email = 'Please enter a valid Gmail address';
        }

        // Mobile number validation
        if (formData.mobile && !/^\d{10}$/.test(formData.mobile)) {
            newErrors.mobile = 'Mobile number must be 10 digits';
        }

        if (formData.guardianMobile && !/^\d{10}$/.test(formData.guardianMobile)) {
            newErrors.guardianMobile = 'Guardian mobile number must be 10 digits';
        }

        // Aadhaar validation
        if (formData.aadhaar && !/^\d{12}$/.test(formData.aadhaar)) {
            newErrors.aadhaar = 'Aadhaar number must be 12 digits';
        }

        // Pin code validation
        if (formData.pinCode && !/^\d{6}$/.test(formData.pinCode)) {
            newErrors.pinCode = 'Pin code must be 6 digits';
        }

        if (!formData.declaration) {
            newErrors.declaration = 'You must accept the declaration';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: '' }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            setApiError('');

            try {
                // First check if there's an existing registration
                const checkResponse = await directRegistrationService.checkExistingRegistration({
                    aadhaar: formData.aadhaar,
                    mobile: formData.mobile
                });

                if (checkResponse.exists && checkResponse.status === 'COMPLETED') {
                    setApiError('You have already completed a registration with this Aadhaar/mobile number.');
                    setLoading(false);
                    return;
                }

                // If there's an incomplete registration, it will be automatically removed
                const formDataToSend = new FormData();
                Object.keys(formData).forEach(key => {
                    formDataToSend.append(key, formData[key]);
                });

                const registrationResponse = await directRegistrationService.submitRegistration(formDataToSend);

                if (registrationResponse.success) {
                    setRegistrationId(registrationResponse.registrationId);
                    setStep('verification');
                }
            } catch (error) {
                if (error.response?.data?.alert) {
                    setApiError(error.response.data.alert);
                } else {
                    setApiError(error.message || 'Registration failed. Please try again.');
                }
            } finally {
                setLoading(false);
            }
        }
    };

    const [isLoading, setIsLoading] = useState(false);

    const handleDeskslip = async () => {
        try {
            console.log('Starting desk slip download process');
            const blob = await directRegistrationService.downloadDeskSlip();

            if (blob.size === 0) {
                console.error('Received empty PDF blob');
                throw new Error('Generated PDF is empty');
            }

            console.log('Creating download URL');
            const url = window.URL.createObjectURL(blob);

            console.log('Setting up download link');
            const link = document.createElement('a');
            link.href = url;
            link.download = `desk_slip_${Date.now()}.pdf`;

            console.log('Triggering download');
            document.body.appendChild(link);
            link.click();

            console.log('Cleaning up resources');
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            toast.success('Desk slip downloaded successfully');

        } catch (error) {
            console.error('Desk slip download error:', error);
            toast.error(error.message || 'Failed to download desk slip');
        }
    };




    const handleVerification = async (e) => {
        e.preventDefault();
        if (!verificationCode.trim()) {
            setApiError('Please enter the verification code');
            return;
        }

        setLoading(true);
        setApiError('');

        try {
            const response = await directRegistrationService.verifyBypassCode({
                registrationId,
                code: verificationCode
            });

            if (response.success) {
                // Update status to COMPLETED after successful verification
                await directRegistrationService.updateRegistrationStatus({
                    registrationId,
                    status: 'COMPLETED'
                });

                setStep('success');
            }
        } catch (error) {
            setApiError(error.response?.data?.alert || error.message || 'Verification failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const renderInput = (name, label, type = 'text', options = {}) => (
        <div className="mb-6">
            <label className="block text-sm font-semibold text-gray-700 mb-2">
                {label} {options.required && <span className="text-red-500">*</span>}
            </label>
            <input
                type={type}
                name={name}
                value={formData[name]}
                onChange={handleChange}
                className={`w-full px-4 py-2 rounded-lg border ${errors[name] ? 'border-[#c02222] focus:ring-[#c02222] bg-red-50' : 'border-gray-300'
                    } focus:outline-none focus:ring-2 ${errors[name] ? 'focus:ring-[#c02222]' : 'focus:ring-blue-20'
                    } transition duration-200`}
                {...options}
            />
            {errors[name] && (
                <p className="mt-2 text-sm text-[#e02424]">{errors[name]}</p>
            )}
        </div>
    );

    // ... (rest of the rendering logic remains the same)


    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
                    <div className="bg-gradient-to-r from-blue-600 to-blue-700 px-6 py-4">
                        <h1 className="text-2xl font-bold text-white text-center">
                            Direct Registration Form
                        </h1>
                    </div>




                    {/* <div className="bg-gradient-to-r from-blue-600 to-blue-700 px-6 py-4">
                        <button
                            onClick={handleDeskslip}
                            disabled={isLoading}
                            className={`text-2xl font-bold text-white text-center hover:opacity-90 transition-opacity ${isLoading ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                        >
                            {isLoading ? 'Downloading...' : 'Download Desk Slip'}
                        </button>
                    </div> */}







                    {apiError && (
                        <div className="bg-red-50 border-l-4 border-red-500 p-4 m-6">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <svg className="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm text-red-700">{apiError}</p>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="p-6 sm:p-8">
                        {step === 'form' && (
                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                                    <div>
                                        {renderInput('name', 'Full Name', 'text', { required: true })}
                                        {renderInput('fatherName', "Father's Name", 'text', { required: true })}
                                        {renderInput('motherName', "Mother's Name", 'text', { required: true })}
                                        {renderInput('dob', 'Date of Birth', 'date', { required: true })}
                                    </div>
                                    <div>
                                        <div className="mb-6">
                                            <label className="block text-sm font-semibold text-gray-700 mb-2">
                                                Gender <span className="text-red-500">*</span>
                                            </label>
                                            <div className="space-x-4">
                                                {['Male', 'Female'].map(option => (
                                                    <label key={option} className="inline-flex items-center">
                                                        <input
                                                            type="radio"
                                                            name="gender"
                                                            value={option}
                                                            checked={formData.gender === option}
                                                            onChange={handleChange}
                                                            className="form-radio h-4 w-4 text-blue-600"
                                                        />
                                                        <span className="ml-2">{option}</span>
                                                    </label>
                                                ))}
                                            </div>
                                            {errors.gender && (
                                                <p className="mt-2 text-sm text-red-600">{errors.gender}</p>
                                            )}
                                        </div>

                                        <div className="mb-6">
                                            <label className="block text-sm font-semibold text-gray-700 mb-2">
                                                Category <span className="text-red-500">*</span>
                                            </label>
                                            <div className="grid grid-cols-2 gap-4">
                                                {['GEN', 'OBC', 'ST/SC', 'OTHER'].map(option => (
                                                    <label key={option} className="inline-flex items-center">
                                                        <input
                                                            type="radio"
                                                            name="category"
                                                            value={option}
                                                            checked={formData.category === option}
                                                            onChange={handleChange}
                                                            className="form-radio h-4 w-4 text-blue-600"
                                                        />
                                                        <span className="ml-2">{option}</span>
                                                    </label>
                                                ))}
                                            </div>
                                            {errors.category && (
                                                <p className="mt-2 text-sm text-red-600">{errors.category}</p>
                                            )}
                                        </div>

                                        {renderInput('class', 'Class', 'text', { required: true })}
                                        {renderInput('mobile', 'Mobile Number', 'tel', { required: true })}
                                        {renderInput('guardianMobile', "Guardian's Mobile Number", 'tel', { required: true })}
                                    </div>
                                </div>

                                <div className="space-y-6">
                                    {renderInput('aadhaar', 'Aadhaar Number', 'text', { required: true })}
                                    {renderInput('email', 'Email', 'email', { required: true })}

                                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                                        {renderInput('city', 'City/Village', 'text', { required: true })}
                                        {renderInput('district', 'District', 'text', { required: true })}
                                        {renderInput('state', 'State', 'text', { required: true })}
                                        {renderInput('pinCode', 'Pin Code', 'text', { required: true })}
                                    </div>

                                    <div className="space-y-4">
                                        <div className="flex items-start">
                                            <input
                                                type="checkbox"
                                                name="declaration"
                                                checked={formData.declaration}
                                                onChange={handleChange}
                                                className="mt-1 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                            />
                                            <label className="ml-2 block text-sm text-gray-700">
                                                I hereby declare that the information furnished above is true, complete
                                                and correct to the best of my knowledge and belief.
                                            </label>
                                        </div>
                                        {errors.declaration && (
                                            <p className="text-red-500 text-sm">{errors.declaration}</p>
                                        )}
                                    </div>

                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            disabled={loading}
                                            className="w-full sm:w-auto px-6 py-3 bg-yellow-50 text-black hover:text-white font-medium rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors disabled:opacity-50"
                                        >
                                            {loading ? 'Processing...' : 'Submit Registration'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}

                        {step === 'verification' && (
                            <div className="text-center space-y-6">
                                <h3 className="text-2xl font-semibold text-gray-800">Enter Verification Code</h3>
                                <p className="text-gray-600">Please enter the verification code to complete your registration</p>

                                <div className="bg-white border border-gray-200 p-8 rounded-xl shadow-sm max-w-md mx-auto">
                                    <form onSubmit={handleVerification} className="space-y-4">
                                        <input
                                            type="text"
                                            value={verificationCode}
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                            placeholder="Enter verification code"
                                            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-200 focus:outline-none"
                                            required
                                        />
                                        <button
                                            type="submit"
                                            disabled={loading}
                                            className="w-full px-6 py-3 bg-caribbeangreen-600 text-white font-medium rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all disabled:opacity-50"
                                        >
                                            {loading ? 'Verifying...' : 'Verify Code'}
                                        </button>

                                        {/* Security Badge */}
                                        <div className="mt-4 flex items-center justify-center text-sm text-gray-500">
                                            <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                                            </svg>
                                            Secured and Powered by Coreva.in
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}
                        {step === 'success' && (
                            <div className="text-center space-y-4">
                                <div className="w-24 h-22 text-[#2fd538] text-2xl mx-auto bg-green-100 rounded-full flex items-center justify-center">
                                    <svg
                                        className="w-20 h-20 text-green-600"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M5 13l4 4L19 7"
                                        />
                                    </svg>
                                </div>
                                <h3 className="text-2xl font-semibold">Registration Successful!</h3>
                                <p>Your admit card has been sent to your email address.</p>

                                <button
                                    onClick={() => window.location.href = '/direct-registration'}
                                    className="mt-6 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                                >
                                    Return to Form
                                </button>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default DirectRegistrationForm;