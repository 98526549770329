import { apiConnector } from '../apiConnector';
import { registrationEndpoints } from '../apis';

export const directRegistrationService = {
    checkExistingRegistration: async (data) => {
        try {
            const response = await apiConnector(
                "POST",
                registrationEndpoints.CHECK_EXISTING_REGISTRATION,
                data
            );
            return response.data;
        } catch (error) {
            throw error?.response?.data || error?.message || "Failed to check registration";
        }
    },

    submitRegistration: async (formData) => {
        try {
            const response = await apiConnector(
                "POST",
                registrationEndpoints.DIRECT_REGISTRATION,
                formData
            );

            if (!response?.data?.success) {
                throw new Error(response?.data?.message || "Registration failed");
            }

            return response.data;
        } catch (error) {
            throw error?.response?.data || error?.message || "Registration failed";
        }
    },

    verifyBypassCode: async (data) => {
        try {
            const response = await apiConnector(
                "POST",
                registrationEndpoints.VERIFY_CODE,
                data
            );
            if (!response?.data?.success) {
                throw new Error(response?.data?.message || "Code verification failed");
            }
            return response.data;
        } catch (error) {
            throw error?.response?.data || error?.message || "Code verification failed";
        }
    },

    updateRegistrationStatus: async (data) => {
        try {
            const response = await apiConnector(
                "POST",
                registrationEndpoints.UPDATE_STATUS,
                data
            );
            if (!response?.data?.success) {
                throw new Error(response?.data?.message || "Status update failed");
            }
            return response.data;
        } catch (error) {
            throw error?.response?.data || error?.message || "Status update failed";
        }
    },



    downloadDeskSlip: async () => {
        try {
            const response = await apiConnector(
                "GET",
                registrationEndpoints.DESKSLIP_DOWNLOAD,
                null,
                {
                    responseType: 'arraybuffer',
                    headers: {
                        'Accept': 'application/pdf',
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache'
                    }
                }
            );

            // Validate response
            if (!response?.data) {
                console.error('No data received in response');
                throw new Error('No data received from server');
            }

            // Log response details
            console.log('Response details:', {
                dataSize: response.data.byteLength,
                headers: response.headers,
                status: response.status
            });

            // Create and validate blob
            const blob = new Blob([response.data], {
                type: 'application/pdf'
            });

            // Log blob details
            console.log('Blob details:', {
                size: blob.size,
                type: blob.type
            });

            if (blob.size === 0) {
                throw new Error('Empty PDF received');
            }

            return blob;

        } catch (error) {
            console.error('Download error:', error);

            // Handle array buffer error responses
            if (error.response?.data instanceof ArrayBuffer) {
                try {
                    const text = new TextDecoder().decode(error.response.data);
                    const json = JSON.parse(text);
                    throw new Error(json.error || 'Failed to download desk slip');
                } catch (e) {
                    console.error('Error parsing error response:', e);
                }
            }

            throw new Error(error.message || 'Failed to download desk slip');
        }
    }



};