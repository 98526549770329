import React from "react";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CTAButton from "../../../components/core/HomePage/Button";
import { FaArrowRight } from "react-icons/fa";
import samplePDF from '../../../assets/Images/freepdf.pdf';
import { ACCOUNT_TYPE } from "../../../utils/constants";

const CodeBlocks = ({
  position,
  heading,
  subheading,
  ctabtn1,
  ctabtn2,
}) => {
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);
  const navigate = useNavigate();

  const handleDownloadClick = () => {
    if (token && user?.accountType !== ACCOUNT_TYPE.INSTRUCTOR) {
      const link = document.createElement('a');
      link.href = samplePDF;
      link.download = 'freepdf.pdf';
      link.click();
    } else {
      navigate("/signup");
    }
  };

  return (
    <div className={`flex ${position} my-20 justify-between flex-col w-11/12 gap-10`}>
      {/* Section 1 */}
      <div className="  items-center text-center flex flex-col gap-8">
        <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold">{heading}</h1>
  
        {/* Sub Heading */}
        <div className=" text-richblack-600 font-medium w-full md:w-[85%] -mt-3 mb-10 ">
          {subheading}
        </div>
  
        {/* Section 2 - PDF Viewer */}
        {/* <div className="w-full flex flex-col md:flex-row justify-between gap-8 ml-0 ">
          <div className="h-[350px] lg:h-[450px] w-full md:w-[470px] flex flex-col overflow-hidden relative">
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.11.338/build/pdf.worker.min.js`}>
              <Viewer fileUrl={samplePDF} />
            </Worker>
            <button
              className="absolute bottom-2 right-2 bg-red-500 text-black py-2 px-4 rounded hover:bg-red-600 transition-colors duration-300"
              onClick={handleDownloadClick}
            >
              Download PDF
            </button>
          </div>
          <div className="h-[350px] lg:h-[450px] w-full md:w-[470px] flex flex-col overflow-hidden relative">
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.11.338/build/pdf.worker.min.js`}>
              <Viewer fileUrl={samplePDF} />
            </Worker>
            <button
              className="absolute bottom-2 right-2 bg-red-500 text-black py-2  rounded hover:bg-red-600 transition-colors duration-300"
              onClick={handleDownloadClick}
            >
              Download PDF
            </button>
          </div>
        </div> */}
  
        {/* Button Group */}
        <div className="flex gap-7 mt-7 justify-center">
          <CTAButton active={ctabtn1.active} linkto={ctabtn1.link}>
            <div className="flex items-center gap-2">
              {ctabtn1.btnText}
              <FaArrowRight />
            </div>
          </CTAButton>
          <CTAButton active={ctabtn2.active} linkto={ctabtn2.link}>
            {ctabtn2.btnText}
          </CTAButton>
        </div>
      </div>
    </div>
  );
  
};

export default CodeBlocks;
