import React from 'react';

const Alert = ({ message, type = 'error', onClose }) => {
  const [isVisible, setIsVisible] = React.useState(true);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose?.();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  if (!isVisible) return null;

  const alertStyles = {
    wrapper: {
      position: 'fixed',
      top: '20px',
      right: '20px',
      zIndex: 1050,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    box: {
      padding: '15px 20px',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      minWidth: '280px',
      fontFamily: 'Arial, sans-serif',
    },
    closeButton: {
      background: 'none',
      border: 'none',
      color: 'inherit',
      fontSize: '20px',
      cursor: 'pointer',
      marginLeft: '10px',
    },
    error: {
      backgroundColor: '#f8d7da',
      color: '#721c24',
      border: '1px solid #f5c6cb',
    },
    success: {
      backgroundColor: '#d4edda',
      color: '#155724',
      border: '1px solid #c3e6cb',
    },
    warning: {
      backgroundColor: '#fff3cd',
      color: '#856404',
      border: '1px solid #ffeeba',
    },
    info: {
      backgroundColor: '#d1ecf1',
      color: '#0c5460',
      border: '1px solid #bee5eb',
    },
  };

  const getTypeStyle = () => {
    switch (type) {
      case 'success': return alertStyles.success;
      case 'warning': return alertStyles.warning;
      case 'info': return alertStyles.info;
      default: return alertStyles.error;
    }
  };

  return (
    <div style={alertStyles.wrapper}>
      <div style={{ ...alertStyles.box, ...getTypeStyle() }} role="alert">
        <span>{message}</span>
        <button
          onClick={() => {
            setIsVisible(false);
            onClose?.();
          }}
          style={alertStyles.closeButton}
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default Alert;
