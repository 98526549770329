import React, { useState, useEffect } from 'react';
import Alert from '../components/alert'; // Import the Alert component
import { registrationService } from '../services/operations/examform';

const ExamRegistrationForm = () => {
    const [step, setStep] = useState('form');
    const [loading, setLoading] = useState(false);
    const [orderId, setOrderId] = useState(null);
    const [alert, setAlert] = useState(null);

    const [formData, setFormData] = useState({
        name: '',
        fatherName: '',
        motherName: '',
        dob: '',
        gender: '',
        category: '',
        class: '',
        mobile: '',
        guardianMobile: '',
        aadhaar: '',
        email: '',
        city: '',
        district: '',
        state: '',
        pinCode: '',
        declaration: true
    });

    const [errors, setErrors] = useState({});
    // const [errors, setErrors] = useState({});
    const [apiError, setApiError] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const showAlert = (message, type = 'error') => {
        setAlert({ message, type });
    };

    const validateForm = () => {
        const newErrors = {};
        let errorMessages = [];

        // Required field validation
        Object.keys(formData).forEach(key => {
            if (!formData[key] && key !== 'declaration') {
                const fieldName = key.charAt(0).toUpperCase() + key.slice(1);
                newErrors[key] = `${fieldName} is required`;
                errorMessages.push(`${fieldName} is required`);
            }
        });

        // Email validation
        if (formData.email && !/^[A-Z0-9._%+-]+@gmail\.com$/i.test(formData.email)) {
            newErrors.email = 'Please enter a valid Gmail address';
            errorMessages.push('Please enter a valid Gmail address');
        }

        // Mobile number validation
        if (formData.mobile && !/^\d{10}$/.test(formData.mobile)) {
            newErrors.mobile = 'Mobile number must be 10 digits';
            errorMessages.push('Mobile number must be 10 digits');
        }

        if (formData.guardianMobile && !/^\d{10}$/.test(formData.guardianMobile)) {
            newErrors.guardianMobile = 'Guardian mobile number must be 10 digits';
            errorMessages.push('Guardian mobile number must be 10 digits');
        }

        // Aadhaar validation
        if (formData.aadhaar && !/^\d{12}$/.test(formData.aadhaar)) {
            newErrors.aadhaar = 'Aadhaar number must be 12 digits';
            errorMessages.push('Aadhaar number must be 12 digits');
        }

        // Pin code validation
        if (formData.pinCode && !/^\d{6}$/.test(formData.pinCode)) {
            newErrors.pinCode = 'Pin code must be 6 digits';
            errorMessages.push('Pin code must be 6 digits');
        }

        // Declaration validation
        if (!formData.declaration) {
            newErrors.declaration = 'You must accept the declaration';
            errorMessages.push('You must accept the declaration');
        }

        setErrors(newErrors);

        // Show alert if there are errors
        if (errorMessages.length > 0) {
            showAlert(errorMessages.join('\n'), 'error');
            return false;
        }

        return true;
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));

        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: '' }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);

            try {
                const formDataToSend = new FormData();
                Object.keys(formData).forEach(key => {
                    formDataToSend.append(key, formData[key]);
                });

                const registrationResponse = await registrationService.submitRegistration(formDataToSend);

                if (registrationResponse.success) {
                    const orderResponse = await registrationService.createPaymentOrder({
                        amount: 100 * 100,
                    }, registrationResponse.registrationId);

                    setOrderId(orderResponse.orderId);
                    setStep('payment');
                    showAlert('Registration submitted successfully!', 'success');
                }
            } catch (error) {
                showAlert(error.response?.data?.alert || error.message || 'Registration failed. Please try again.', 'error');
            } finally {
                setLoading(false);
            }
        }
    };


    const handlePayment = async () => {
        setLoading(true);
        setApiError('');

        try {
            const options = {
                key: process.env.NEXT_PUBLIC_RAZORPAY_KEY_ID,
                amount: 100 * 100,
                currency: 'INR',
                name: 'Exam Registration',
                description: 'Registration Fee Payment',
                order_id: orderId,
                prefill: {
                    name: formData.name,
                    email: formData.email,
                    contact: formData.mobile
                },
                handler: async function (response) {
                    try {
                        const verifyResponse = await registrationService.verifyPayment({
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_signature: response.razorpay_signature
                        });

                        if (verifyResponse.success) {
                            setStep('success');
                        }
                    } catch (error) {
                        setApiError('Payment verification failed. Please contact support.');
                    }
                },
                theme: {
                    color: '#2563EB'
                }
            };

            const razorpay = new window.Razorpay(options);
            razorpay.open();
        } catch (error) {
            setApiError('Payment initialization failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const renderInput = (name, label, type = 'text', options = {}) => (
        <div className="mb-6">
            <label className="block text-sm font-semibold text-gray-700 mb-2">
                {label} {options.required && <span className="text-red-500">*</span>}
            </label>
            <input
                type={type}
                name={name}
                value={formData[name]}
                onChange={handleChange}
                className={`w-full px-4 py-2 rounded-lg border ${errors[name] ? 'border-red-500 bg-red-50' : 'border-gray-300'
                    } focus:outline-none focus:ring-2 ${errors[name] ? 'focus:ring-red-200' : 'focus:ring-blue-200'
                    } transition duration-200`}
                {...options}
            />
            {errors[name] && (
                <p className="mt-2 text-sm text-red-600">{errors[name]}</p>
            )}
        </div>
    );

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-12 px-4 sm:px-6 lg:px-8">
            <div className="relative">
                {alert && (
                    <Alert
                        message={alert.message}
                        type={alert.type}
                        onClose={() => setAlert(null)}
                    />
                )}
                {/* Rest of your form JSX */}
            </div>
            <div className="max-w-7xl mx-auto">
                <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
                    <div className="bg-gradient-to-r from-blue-600 to-blue-700 px-6 py-4">
                        <h1 className="text-2xl font-bold text-white text-center">
                            Exam Registration Form
                        </h1>
                    </div>

                    {apiError && (
                        <div className="bg-red-50 border-l-4 border-red-500 p-4 m-6">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <svg className="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm text-red-700">{apiError}</p>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="p-6 sm:p-8">
                        {step === 'form' && (
                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                                    <div>
                                        {renderInput('name', 'Full Name', 'text', { required: true })}
                                        {renderInput('fatherName', "Father's Name", 'text', { required: true })}
                                        {renderInput('motherName', "Mother's Name", 'text', { required: true })}
                                        {renderInput('dob', 'Date of Birth', 'date', { required: true })}
                                    </div>
                                    <div>
                                        <div className="mb-6">
                                            <label className="block text-sm font-semibold text-gray-700 mb-2">
                                                Gender <span className="text-red-500">*</span>
                                            </label>
                                            <div className="space-x-4">
                                                {['Male', 'Female'].map(option => (
                                                    <label key={option} className="inline-flex items-center">
                                                        <input
                                                            type="radio"
                                                            name="gender"
                                                            value={option}
                                                            checked={formData.gender === option}
                                                            onChange={handleChange}
                                                            className="form-radio h-4 w-4 text-blue-600"
                                                        />
                                                        <span className="ml-2">{option}</span>
                                                    </label>
                                                ))}
                                            </div>
                                            {errors.gender && (
                                                <p className="mt-2 text-sm text-red-600">{errors.gender}</p>
                                            )}
                                        </div>

                                        <div className="mb-6">
                                            <label className="block text-sm font-semibold text-gray-700 mb-2">
                                                Category <span className="text-red-500">*</span>
                                            </label>
                                            <div className="grid grid-cols-2 gap-4">
                                                {['GEN', 'OBC', 'ST/SC', 'OTHER'].map(option => (
                                                    <label key={option} className="inline-flex items-center">
                                                        <input
                                                            type="radio"
                                                            name="category"
                                                            value={option}
                                                            checked={formData.category === option}
                                                            onChange={handleChange}
                                                            className="form-radio h-4 w-4 text-blue-600"
                                                        />
                                                        <span className="ml-2">{option}</span>
                                                    </label>
                                                ))}
                                            </div>
                                            {errors.category && (
                                                <p className="mt-2 text-sm text-red-600">{errors.category}</p>
                                            )}
                                        </div>

                                        {renderInput('class', 'Class', 'text', { required: true })}
                                        {renderInput('mobile', 'Mobile Number', 'tel', { required: true })}
                                        {renderInput('guardianMobile', "Guardian's Mobile Number", 'tel', { required: true })}
                                    </div>
                                </div>

                                <div className="space-y-6">
                                    {renderInput('aadhaar', 'Aadhaar Number', 'text', { required: true })}
                                    {renderInput('email', 'Email', 'email', { required: true })}

                                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                                        {renderInput('city', 'City/Village', 'text', { required: true })}
                                        {renderInput('district', 'District', 'text', { required: true })}
                                        {renderInput('state', 'State', 'text', { required: true })}
                                        {renderInput('pinCode', 'Pin Code', 'text', { required: true })}
                                    </div>

                                    <div className="space-y-4">
                                        <div className="flex items-start">
                                            <input
                                                type="checkbox"
                                                name="declaration"
                                                checked={formData.declaration}
                                                onChange={handleChange}
                                                className="mt-1 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                            />
                                            <label className="ml-2 block text-sm text-gray-700">
                                                I hereby declare that the information furnished above is true, complete
                                                and correct to the best of my knowledge and belief.
                                            </label>
                                        </div>
                                        {errors.declaration && (
                                            <p className="text-red-500 text-sm">{errors.declaration}</p>
                                        )}
                                    </div>

                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            disabled={loading}
                                            className="w-full sm:w-auto px-6 py-3 bg-yellow-50 text-black hover:text-white font-medium rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors disabled:opacity-50"
                                        >
                                            {loading ? 'Processing...' : 'Proceed to Payment'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}

                        {step === 'payment' && (
                            <div className="text-center space-y-6">
                                <h3 className="text-2xl font-semibold text-gray-800">Complete Your Registration</h3>
                                <p className="text-gray-600">Please proceed with the secure payment to confirm your spot</p>

                                <div className="bg-white border border-gray-200 p-8 rounded-xl shadow-sm max-w-md mx-auto">
                                    {/* Fee Display */}
                                    <div className="bg-gray-50 p-4 rounded-lg mb-6">
                                        <p className="text-sm text-gray-600">Total Amount</p>
                                        <p className="text-3xl font-bold text-gray-800 mb-1">₹100</p>
                                        <p className="text-xs text-gray-500">One-time Registration Fee</p>
                                    </div>

                                    {/* Important Notes */}
                                    <div className="text-left mb-6">
                                        <p className="text-sm font-medium text-gray-700 mb-2">Important Notes:</p>
                                        <ul className="text-sm text-gray-600 space-y-1">
                                            <li>• Payment confirmation email will be sent within 5 minutes</li>
                                            <li>• Ensure stable internet connection during payment</li>
                                            <li>• If payment fails, wait 10 minutes before retrying</li>
                                            <li>• Do not refresh page during transaction</li>
                                        </ul>
                                    </div>

                                    {/* Payment Button */}
                                    <button
                                        onClick={handlePayment}
                                        disabled={loading}
                                        className="w-full px-6 py-3 bg-caribbeangreen-600 text-white font-medium rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all disabled:opacity-50"
                                    >
                                        {loading ? (
                                            <div className="flex items-center justify-center space-x-2">
                                                <div className="w-5 h-5 border-2 border-white  border-t-transparent rounded-full animate-spin" />
                                                <span>Processing Payment...</span>
                                            </div>
                                        ) : (
                                            'Proceed to Pay ₹100'
                                        )}
                                    </button>

                                    {/* Security Badge */}
                                    <div className="mt-4 flex items-center justify-center text-sm text-gray-500">
                                        <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                                        </svg>
                                        Secured and Powered by Coreva.in
                                    </div>
                                </div>
                            </div>
                        )}

                        {step === 'success' && (
                            <div className="text-center space-y-4">
                                <div className="w-24 h-22 text-[#2fd538] text-2xl mx-auto bg-green-100 rounded-full flex items-center justify-center">
                                    <svg
                                        className="w-20 h-20 text-green-600"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M5 13l4 4L19 7"
                                        />
                                    </svg>
                                </div>
                                <h3 className="text-4xl font-semibold text-black">
                                    Registration Successful!
                                </h3>
                                <p className="text-black">
                                    Your payment has been confirmed and your admit card has been sent to your email address.
                                    Please check your inbox.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExamRegistrationForm;