import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FaTrophy, FaUserGraduate, FaMoneyBillWave,
  FaCalendarAlt, FaClock, FaMapMarkerAlt,
  FaCheckCircle, FaBook, FaUsers, FaChalkboardTeacher,
  FaMedal, FaStar, FaCertificate, FaLightbulb,
  FaUniversity, FaGraduationCap
} from 'react-icons/fa';

const ScholarshipInfo = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const CountdownTimer = () => {
    const [timeLeft, setTimeLeft] = useState({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    });

    useEffect(() => {
      const targetDate = new Date('2025-03-15T23:59:59').getTime();

      const calculateTimeLeft = () => {
        const now = new Date().getTime();
        const difference = targetDate - now;

        if (difference > 0) {
          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);

          setTimeLeft({ days, hours, minutes, seconds });
        } else {
          // If countdown is finished
          setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        }
      };

      // Calculate initially
      calculateTimeLeft();

      // Update every second
      const timer = setInterval(calculateTimeLeft, 1000);

      // Cleanup
      return () => clearInterval(timer);
    }, []);

    return (
      <div className="flex justify-center space-x-4 text-white">
        {Object.entries(timeLeft).map(([unit, value]) => (
          <div key={unit} className="text-center">
            <div className="bg-blue-800 rounded-lg px-4 py-2 min-w-[80px]">
              <div className="text-2xl font-bold">{String(value).padStart(2, '0')}</div>
              <div className="text-xs uppercase">{unit}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleRegister = () => {
    navigate('/Examination-Registration');
  };


  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section with Video Background */}
      {/* Hero Section with Video Background */}
      <div className="relative overflow-hidden text-white py-24">
        <div className="absolute inset-0 bg-gradient-to-r bg-white opacity-90" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className={`text-center transition-all duration-1000 transform ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}>
            <h1 className="text-5xl md:text-6xl font-bold mb-6 bg-clip-text bg-gradient-to-r text-black">
              Excel Scholarship Test 2025
            </h1>
            <p className="text-xl md:text-2xl text-blue-100 mb-8">
              Empowering Brilliance, Rewarding Excellence
            </p>
            <div className="mb-12">
              <CountdownTimer />
              <p className="mt-4 text-blue-200">Registration Closing Soon</p>
            </div>
            <button
              onClick={handleRegister}
              className="bg-yellow-25 text-blue-900 text-lg font-semibold px-12 py-4 rounded-full hover:bg-blue-50 transform hover:-translate-y-1 transition-all duration-300 shadow-xl"
            >
              Register Now
            </button>
          </div>
        </div>
      </div>

      {/* Key Statistics */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              { number: "500+", label: "Students Expected", icon: FaUsers },
              { number: "₹50,000", label: "Maximum Scholarship", icon: FaTrophy },
              { number: "1", label: "Exam Centers", icon: FaUniversity },
              { number: "98%", label: "Success Rate", icon: FaGraduationCap }
            ].map((stat, index) => (
              <div key={index} className="text-center">
                <stat.icon className="text-4xl text-blue-600 mx-auto mb-4" />
                <div className="text-3xl font-bold text-gray-800">{stat.number}</div>
                <div className="text-gray-600">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Scholarship Tiers */}
      <div className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold text-center text-gray-800 mb-16">
            Scholarship Awards
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                icon: FaTrophy,
                rank: "Gold Tier",
                percentage: "100%",
                benefits: [
                  "Full tuition fee waiver",
                  "Study material worth ₹10,000",
                  "Personal mentor",
                  "Monthly assessments"
                ],
                color: "from-yellow-400 to-yellow-600"
              },
              {
                icon: FaMedal,
                rank: "Silver Tier",
                percentage: "75%",
                benefits: [
                  "75% tuition fee waiver",
                  "Study material worth ₹7,500",
                  "Group mentoring sessions",
                  "Quarterly assessments"
                ],
                color: "from-gray-400 to-gray-600"
              },
              {
                icon: FaCertificate,
                rank: "Bronze Tier",
                percentage: "50%",
                benefits: [
                  "50% tuition fee waiver",
                  "Study material worth ₹5,000",
                  "Access to online resources",
                  "Half-yearly assessments"
                ],
                color: "from-orange-400 to-orange-600"
              }
            ].map((tier, index) => (
              <div key={index} className="relative group">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-800 rounded-2xl transform group-hover:scale-[1.02] transition-transform duration-300" />
                <div className="relative bg-white rounded-xl shadow-lg p-8 transform group-hover:-translate-y-2 transition-transform duration-300">
                  <div className={`w-16 h-16 mx-auto mb-6 rounded-full flex items-center justify-center bg-gradient-to-r ${tier.color}`}>
                    <tier.icon className="text-3xl text-white" />
                  </div>
                  <h3 className="text-2xl font-bold text-center text-gray-800 mb-4">
                    {tier.rank}
                  </h3>
                  <p className="text-4xl font-bold text-center text-blue-600 mb-6">
                    {tier.percentage}
                  </p>
                  <ul className="space-y-3">
                    {tier.benefits.map((benefit, i) => (
                      <li key={i} className="flex items-center text-gray-600">
                        <FaCheckCircle className="text-green-500 mr-2 flex-shrink-0" />
                        <span>{benefit}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Program Benefits */}
      <div className="bg-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold text-center text-gray-800 mb-16">
            Why Choose Excel Classes?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                icon: FaChalkboardTeacher,
                title: "Expert Faculty",
                description: "Learn from IIT/NIT alumni with years of teaching excellence"
              },
              {
                icon: FaBook,
                title: "Comprehensive Material",
                description: "Structured study material designed by subject matter experts"
              },
              {
                icon: FaLightbulb,
                title: "Innovative Teaching",
                description: "Interactive sessions with modern teaching methodologies"
              },
              {
                icon: FaUsers,
                title: "Peer Learning",
                description: "Collaborate with motivated students for enhanced learning"
              },
              {
                icon: FaStar,
                title: "Track Record",
                description: "Consistent results with 90%+ selection rate in competitive exams"
              },
              {
                icon: FaCertificate,
                title: "Recognition",
                description: "Get certified and build your academic portfolio"
              }
            ].map((benefit, index) => (
              <div key={index} className="bg-gray-50 rounded-xl p-6 transform hover:scale-105 transition-all duration-300">
                <benefit.icon className="text-4xl text-blue-600 mb-4" />
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  {benefit.title}
                </h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Exam Details */}
      <div className="bg-blue-900 text-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold text-center mb-16">
            Examination Details
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div>
              <h3 className="text-2xl font-semibold mb-6">
                Exam Pattern
              </h3>
              <ul className="space-y-4">
                {[
                  "3-hour objective type test",
                  "Physics, Chemistry, Mathematics sections",
                  "NCERT-based syllabus",
                  "Multiple choice questions",
                  "Negative marking scheme"
                ].map((item, index) => (
                  <li key={index} className="flex items-center">
                    <FaCheckCircle className="text-blue-300 mr-3" />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-6">
                Important Dates
              </h3>
              <div className="space-y-4">
                {[
                  { date: "Mar 15, 2025", event: "Registration Deadline" },
                  { date: "Mar 23, 2025", event: "Examination Date" },
                  { date: "Mar 30, 2025", event: "Result Declaration" },
                  { date: "Apr 01, 2025", event: "Counseling Begins" }
                ].map((item, index) => (
                  <div key={index} className="flex items-center">
                    <FaCalendarAlt className="text-blue-300 mr-3" />
                    <div>
                      <div className="font-semibold">{item.date}</div>
                      <div className="text-blue-200">{item.event}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-blue-800 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-4xl font-bold text-white mb-8">
            Begin Your Journey to Excellence
          </h2>
          <p className="text-xl text-blue-100 mb-12 max-w-2xl mx-auto">
            Join the league of exceptional students and unlock your potential with Excel Classes' comprehensive coaching program.
          </p>
          <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-6">
            <button
              onClick={handleRegister}
              className="bg-white text-blue-900 text-lg font-semibold px-12 py-4 rounded-full hover:bg-blue-50 transform hover:-translate-y-1 transition-all duration-300 shadow-xl"
            >
              Register Now - ₹100 Only
            </button>
            <a href="#" className="text-white hover:text-blue-200 transition-colors duration-300">
              Download Brochure →
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScholarshipInfo;